<template>
   <div class="welcome-screen">
    <div class="welcome-card">
      <div class="welcome-header">
        <CIcon
                style="width: 5rem; height: 5rem; color: #2E2860;"
                :content="$options.freeSet.cilMoodVeryGood"
            />
            <!--<img src="img\gesto\logo.png" style="width: 5rem; height: 4rem; color: #2E2860;" />-->
    <h1 class="welcome-title">Bienvenido a Gesto.</h1>
      </div>
      <p class="user-greeting">
        Hola, <span class="user-name">{{ nombreUsuario }}</span>
      </p>
      <p class="welcome-message">
        Nos alegra verte por aquí. Esperamos que tengas una excelente experiencia.
      </p>
      <p class="support-message">
        Esperamos que tengas un buen día, recuerda que puedes contactarnos a <a href="mailto:soporte@vapp.cl" class="support-email">soporte@vapp.cl</a>
      </p>
    </div>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
export default {
  name: "PrincipalUsuario",
  freeSet,
  data() {
    return {
      nombreUsuario: (JSON.parse(this.$cookie.get("userLogginToken")).tipoUsuario == "trabajador")? JSON.parse(this.$cookie.get("userLogginToken")).nombreTrabajador.split(" ")[0] : JSON.parse(this.$cookie.get("userLogginToken")).nombreUsuario,
    };
  },
};
</script>
<style scoped>
.welcome-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 1rem;
}

.welcome-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
}

.welcome-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.carrot-image {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin-right: 1rem;
}

.welcome-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.user-greeting {
  font-size: 1.2rem;
  color: #4b5563;
  margin-bottom: 1rem;
}

.user-name {
  font-weight: 500;
  color: #2563eb;
}

.welcome-message {
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.5;
}
</style>
